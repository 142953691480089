import { httpClient } from "../../HttpClientV1/HttpClient";
import router from "../../router/index";
import axios from "axios";
export default {
  state: {
    GeneralMethods: [],
    PaymentsMethods: [],
    general_code: null,
    method_code: null,
    amount: "",
    eur: null,
    usd: null,
    uah: null,
    general_index: null,
    payments_method: null,
    order_id: null,
    pay_link: null,
    error_pay_create: null,
    locale: localStorage.getItem("locale"),

    dataCloudPayments: {
      email: "",
      invoiceId: "",
      publicId: "",
      rub: 0.0,
    },
    dataRobokassaPay: {
      settings: null,
    },
  },
  mutations: {
    updatePayLink(state, data) {
      state.pay_link = data;
    },
    updateOrderId(state, data) {
      state.order_id = data;
    },
    updatePaymentsMethod(state, data) {
      state.payments_method = data;
    },
    updateGeneralCode(state, data) {
      state.general_code = data;
    },
    updateMethodCode(state, data) {
      state.method_code = data;
    },
    updateIndex(state, data) {
      state.general_index = data;
    },
    updateEur(state, data) {
      state.eur = data;
    },
    updateUsd(state, data) {
      state.usd = data;
    },
    updateUah(state, data) {
      state.uah = data;
    },
    updateAmount(state, data) {
      state.amount = data;
    },
    updateGeneralMethods(state, data) {
      state.GeneralMethods = data;
    },
    updatePaymentsMethods(state, data) {
      state.PaymentsMethods = data;
    },
    updateErrorPayCreate(state, data) {
      state.error_pay_create = data;
    },
    updateDataCloudPayments(state, data) {
      state.dataCloudPayments.email = data.email;
      state.dataCloudPayments.accountId = data.account_id;
      state.dataCloudPayments.invoiceId = data.invoiceId;
      state.dataCloudPayments.publicId = data.publicId;
      state.dataCloudPayments.rub = parseFloat(parseFloat(data.rub).toFixed(2));
    },
    updateRobokassaPay(state, data) {
      state.dataRobokassaPay.settings = data;
    },
  },
  actions: {
    setGeneralCode(ctx, data) {
      ctx.commit("updateGeneralCode", ctx.state.GeneralMethods[data].code);
    },
    setIndex(ctx, data) {
      ctx.commit("updateIndex", data);
    },
    setMethodCode(ctx, data) {
      ctx.commit("updateMethodCode", data);
    },
    setAmount(ctx, data) {
      ctx.commit("updateAmount", data);
      ctx.dispatch("fetchConvert");
    },
    fetchConvert(ctx) {
      var data = {
        amount: ctx.state.amount,
        general_code: ctx.state.general_code,
        method_code: ctx.state.method_code,
      };
      httpClient
        .post("easypay/api/v4/payments/convert", data)
        .then((response) => {
          ctx.commit("updateEur", response.data.rub);
          ctx.commit("updateUsd", response.data.usd);
          ctx.commit("updateUah", response.data.uah);
        });
    },
    fetchСreate(ctx) {
      var data = {
        amount: ctx.state.amount,
        general_code: ctx.state.general_code,
        method_code: ctx.state.method_code,
      };
      httpClient
        .post(
          "easypay/api/v4/payments/create?locale=" +
            localStorage.getItem("locale").toLowerCase(),
          data
        )
        .then((response) => {
          try {
            if (typeof response.data["error"] !== undefined) {
              var error = "";
              if (localStorage.getItem("locale") == "En") {
                error = response.data.error_en;
              } else {
                error = response.data.error;
              }

              if (typeof response.data["response"] !== undefined) {
                if (typeof response.data.response["errors"] !== undefined) {
                  error += "<br>" + response.data.response.errors.amount;
                }
              }
              ctx.commit("updateErrorPayCreate", error);
            }
          } catch {
            console.error("error");
          }
          try {
            if (typeof response.data["error"] !== undefined) {
              var error = "";
              if (localStorage.getItem("locale") == "En") {
                error = response.data.error_en;
              } else {
                error = response.data.error;
              }
              ctx.commit("updateErrorPayCreate", error);
            }
          } catch {
            console.error("error");
          }
          ctx.commit("updateAmount", response.data.amount);
          ctx.commit("updateGeneralCode", response.data.general_name);
          ctx.commit("updatePaymentsMethod", response.data.payments_method);
          ctx.commit("updateOrderId", response.data.order_id);
          ctx.commit("updatePayLink", response.data.pay_link);
          if (response.data.general_name == "CloudPayments") {
            ctx.commit("updateDataCloudPayments", response.data);
          }
          if (response.data.general_name == "Robokassa") {
            ctx.commit("updateRobokassaPay", response.data.setting);
          }
          router.push("/confirmPay");
        });
    },
    fetchGeneralMethods(ctx) {
      httpClient
        .get(
          "easypay/api/v4/payments/general_methods?locale=" +
            localStorage.getItem("locale").toLowerCase()
        )
        .then((response) => {
          ctx.commit("updateGeneralMethods", response.data);
          ctx.commit("updateGeneralCode", response.data[0].code);
        });
    },
    fetchPaymentsMethods(ctx, index) {
      var general_id =
        index == undefined
          ? ctx.state.GeneralMethods[ctx.state.general_index].id
          : 1;
      httpClient
        .get(
          "easypay/api/v4/payments/payments_methods?locale=" +
            localStorage.getItem("locale").toLowerCase() +
            "&general_id=" +
            general_id
        )
        .then((response) => {
          ctx.commit("updatePaymentsMethods", response.data);
          ctx.commit("updateMethodCode", response.data[0].value);
        });
    },
  },
  getters: {
    getPayLink: (state) => state.pay_link,
    getOrderId: (state) => state.order_id,
    getPaymentsMethod: (state) => state.payments_method,
    getGeneralCode: (state) => state.general_code,
    getMethodCode: (state) => state.method_code,
    getEur: (state) => state.eur,
    getUsd: (state) => state.usd,
    getUah: (state) => state.uah,
    getAmount: (state) => state.amount,
    getErrorPayCreated: (state) => state.error_pay_create,
    getGeneralMethods: (state) => state.GeneralMethods,
    getPaymentsMethods: (state) => state.PaymentsMethods,
    getDataCloudPayments: (state) => state.dataCloudPayments,

    getDataRobokassPay: (state) => state.dataRobokassaPay,
  },
};
